<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-filter"/><strong> Filter By User</strong>
      </CCardHeader>
      <CCardBody>
        <CRow class="col-md-12">
                    <CSelect
                    class="col-md-3"
                    :options="user_opt"
                    :value.sync="input.userid"
                    size="sm"
                    placeholder='select'
                    @change="getallData('filter')"
                    />
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
    <CCardHeader>
      <CIcon name="cil-grid"/><strong>Offer List</strong>
    </CCardHeader>
    <CCardBody>
      <div v-if="loading==true">Loading . . .</div>
      <div v-else>
        <CRow>
        <CCol>
          <CRow class="col-md-12">
            <label>Filter :</label>
            <CInput class="col-md-8"
            @keyup="customFilter"
            v-model="filter"
            type="search"
            placeholder="Type to Search" />
            <label>Total Rows : {{totalRows}}</label>
          </CRow>
        </CCol>
        <CCol>
      <div class="card-header-actions">
            <small class="text-muted"
              >
        <multiselect
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect></small
            ></div>
    </CCol>
    </CRow>
    <CDataTable
      :items="items"
      :fields="fields"
      :dark="false"
      :items-per-page="tableperpage"
      pagination
      sorter
      hover
      striped
      border
      small
      fixed
      light
    >
    <!-- column-filter -->
    <template #category="{item}">
      <td>
        <span v-if="item.category == '0'">Mainstream</span>
        <span v-if="item.category == '1'">Adult</span>
      </td>
    </template>
    <template #revenuetrack="{item}">
      <td>
        <span v-if="item.revenuetrack == '0'">Don't Track</span>
        <span v-if="item.revenuetrack == '1'">Fixed ( {{item.revenuevalue}}$ )</span>
        <span v-if="item.revenuetrack == '2'">Automatic (macros)</span>
      </td>
    </template>
      </CDataTable>
      </div> 
       
    </CCardBody>
    </CCard>
  </div>
</template>

<script>
import OfferService from './../../services/offerControlService'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import ManagerService from './../../services/managerControlService'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from "vue-multiselect";

const fields = [
        { key: "_id", label: "Id", sortable: true },
        { key: "offername", label: "Offer Name", sortable: true } ,
        { key: "useremail", label:"User", sortable:true},
        { key: "category", label: "Category", sortable: true },
        { key: "revenuetrack", label: "Revenue track" ,sortable: true },
        // { key: "action", label: "Action" ,sortable: true }
      ]
export default {
  components:{Multiselect},
  props:{small: Boolean},
  name: 'ListOffer',
  data() {
        return {
          users:[],
          user_opt:[],
          input:{userid:''},
          all_data:[],
          filter: '',
          totalRows:0,
          loading:true,
          tableperpage:20,
          perPage:[20,50,100],
          editoffer: false,
          items:[],
          useremail:'',
          fields,
          startDate: 1325376000000,
          endDate: 1330560000000,  
        }
        },
      mounted(){
        if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }else{
        this.useremail = window.localStorage.getItem("email");
        this.getallData();
        if(localStorage.getItem("offer_table") != null)
        this.tableperpage = parseInt(localStorage.getItem("offer_table"));
        this.getUsers()
        }
      },
  methods: {
    async getUsers(){
            let response
        try {
            response = await ManagerService.getuser()
            this.users = response.message.filter(t=>{
            if(t.role != 'manager')
            return t
            })
            this.user_opt = this.users.map(m =>  m.email)
        } catch(e){
            console.error("Error in login")
            console.log(e)
        }
        },
    customFilter() {
      let search = this.filter.toString().toLowerCase()
      if(search != ''){
      let f_data = this.all_data.filter(row => row.offername.match(search))
      if(f_data){return this.items = f_data}
      }
      else{return this.items = this.all_data}
    },
    checkPage(){
      localStorage.setItem("offer_table", this.tableperpage);
    },
    async getallData(txt) {
      try {
        this.loading = true;
        var response
        if(txt){
          response = await OfferService.getManagerOfferList({users: this.input.userid})
          if(response.message == ''){
            Swal.fire({title: 'Error!',text: "Data not available for this user",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
            this.getallData()
          
          }
        }
        else{
          response = await OfferService.getManagerOfferList()
        }
          this.items = response.message;
          this.all_data = response.message;
          this.totalRows = this.items.length;
          this.loading = false;
      } catch (e) {
        console.log('connection error')
        // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok'})
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    async edit(data) {
      this.$root.$data.offer = data;
      this.$root.$data.edit = true;
      this.editoffer = true;
      this.$router.push('/offer/newoffer');

    },
  }

}
</script>

<style>
/* .table  thead  th {
  color: red;
  position: sticky !important;
  top: 0 !important;
} */
</style>
